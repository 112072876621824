import axios, {
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

export const api = axios.create({
  // same CSP headers as in firebase.json
  headers: {
    'Content-Security-Policy':
      "default-src 'none';script-src 'self' 'unsafe-eval' https://apis.google.com https://*.googletagmanager.com *.segment.com https://s3.amazonaws.com *.hotjar.com *.heapanalytics.com *.heap-api.com;connect-src 'self' https://api.github.com/orgs/nf-core/ https://api.github.com/repos/nf-core/ https://*.google-analytics.com https://*.analytics.google.com https://*.googletagmanager.com https://s3.amazonaws.com *.githubusercontent.com  *.sentry.io https://apis.google.com *.webflow.com wss://ws.hotjar.com *.hotjar.com *.hotjar.io *.launchdarkly.com *.segment.com *.segment.io *.googleapis.com *.form.bio *.formbio.com *.cloudfunctions.net https://heapanalytics.com *.googleusercontent.com *.heapanalytics.com https://cdn.heapanalytics.com https://molstarvolseg.ncbr.muni.cz https://webchem.ncbr.muni.cz;img-src 'self' *.githubusercontent.com https://heapanalytics.com *.googleusercontent.com *.webflow.com *.googleapis.com https://*.google-analytics.com https://*.googletagmanager.com;style-src 'self' 'unsafe-inline';base-uri 'self';form-action 'self';font-src 'self';frame-src 'self' https://storage.googleapis.com *.formbio.com *.firebaseapp.com;media-src 'self' *.googleapis.com;worker-src 'self' blob:",
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'DENY',
    'Referrer-Policy': 'same-origin',
    'Permissions-Policy': 'fullscreen=*',
    'Strict-Transport-Security': 'max-age=31536000;includeSubDomains;preload',
  },
});

type InitParams = {
  getToken: () => Promise<string | undefined>;
  baseURL: string;
  onError?: (error: unknown) => void;
  isAdmin: boolean;
};

// These header strings are defined in services/edge/main.go
export const isAdminHeader = 'FormBio-Is-Admin';
export const webRouteHeader = 'FormBio-Web-Route';
export const commitShaHeader = 'FormBio-Commit-SHA';

export function init({ baseURL, getToken, onError, isAdmin }: InitParams) {
  api.defaults.baseURL = baseURL;

  api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    const token = await getToken();
    if (isAdmin) {
      config.headers[isAdminHeader] = 'true';
    }
    if (window.location.href) {
      config.headers[webRouteHeader] = window.location.href;
    }
    config.headers.Authorization = `Bearer ${token}`;
    config.headers[commitShaHeader] = process.env.NEXT_PUBLIC_COMMIT_SHA || '';
    return config;
  });

  if (onError) {
    api.interceptors.response.use(undefined, (error: unknown) => {
      onError(error);
      throw error;
    });
  }
}

export async function apiBatch(requests: Promise<AxiosResponse>[]) {
  return Promise.all(requests);
}

export type { AxiosRequestConfig, AxiosResponse };
